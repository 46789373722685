import React from "react"
import ReCAPTCHA from "react-google-recaptcha"

import './contact-form.scss'

const ContactForm = () => (
  <div className="touch-form">
    <form name="Contact Form" method="POST" data-netlify="true" action="/about-me">
      <h2>Let's talk</h2>
      <input type="hidden" name="form-name" value="Contact Form" />
      <div className="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" name="name" className="form-control" required/>
      </div>
      <div className="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" className="form-control" required/>
      </div>
      <div className="form-group">
        <label for="message">Tell me something:</label>
        <textarea name="message" id="message" className="form-control" required/>
      </div>
      <div className="form-group text-center">
        <ReCAPTCHA sitekey="6LeYwaIUAAAAAIThG0xEvkhZPgZQ8NqJ8S6jtIiT" />
      </div>
      <button type="submit" className="btn btn-primary">Send</button>
    </form>
  </div>
)

export default ContactForm
